
const countries=[

        {
          "code": "MX",
          "phoneCode": "52",
          "name": "Mexico",
          "curSymbol": "S",
          "curCode": "MXN"
        },
        {
          "code": "BD",
          "phoneCode": "880",
          "name": "Bangladesh",
          "curSymbol": "৳",
          "curCode": "BDT"
        },
        {
          "code": "MY",
          "phoneCode": "60",
          "name": "Malaysia",
          "curSymbol": "RM",
          "curCode": "MYR"
        },
        {
          "code": "MZ",
          "phoneCode": "258",
          "name": "Mozambique",
          "curSymbol": "MT",
          "curCode": "MZN"
        },
        {
          "code": "NA",
          "phoneCode": "264",
          "name": "Namibia",
          "curSymbol": "S",
          "curCode": "NAD"
        },
        {
          "code": "NC",
          "phoneCode": "687",
          "name": "New Caledonia",
          "curSymbol": "₣",
          "curCode": "XPF"
        },
        {
          "code": "NE",
          "phoneCode": "227",
          "name": "Niger",
          "curSymbol": "S",
          "curCode": "XOF"
        },
        {
          "code": "NF",
          "phoneCode": "672",
          "name": "Norfolk Island",
          "curSymbol": "S",
          "curCode": "AUD"
        },
        {
          "code": "NG",
          "phoneCode": "234",
          "name": "Nigeria",
          "curSymbol": "₦",
          "curCode": "NGN"
        },
        {
          "code": "NI",
          "phoneCode": "505",
          "name": "Nicaragua",
          "curSymbol": "C$",
          "curCode": "NIO"
        },
        {
          "code": "NL",
          "phoneCode": "31",
          "name": "Netherlands",
          "curSymbol": "€",
          "curCode": "EUR"
        },
        {
          "code": "NO",
          "phoneCode": "47",
          "name": "Norway",
          "curSymbol": "kr",
          "curCode": "NOK"
        },
        {
          "code": "NP",
          "phoneCode": "977",
          "name": "Nepal",
          "curSymbol": "रु",
          "curCode": "NPR"
        },
        {
          "code": "NR",
          "phoneCode": "674",
          "name": "Nauru",
          "curSymbol": "S",
          "curCode": "AUD"
        },
        {
          "code": "NU",
          "phoneCode": "683",
          "name": "Niue",
          "curSymbol": "S",
          "curCode": "NZD"
        },
        {
          "code": "NZ",
          "phoneCode": "64",
          "name": "New Zealand",
          "curSymbol": "S",
          "curCode": "NZD"
        },
        {
          "code": "OM",
          "phoneCode": "968",
          "name": "Oman",
          "curSymbol": "",
          "curCode": "OMR"
        },
        {
          "code": "PA",
          "phoneCode": "507",
          "name": "Panama",
          "curSymbol": "B/.",
          "curCode": "PAB"
        },
        {
          "code": "PE",
          "phoneCode": "51",
          "name": "Peru",
          "curSymbol": "S/",
          "curCode": "PEN"
        },
        {
          "code": "PF",
          "phoneCode": "689",
          "name": "French Polynesia",
          "curSymbol": "₣",
          "curCode": "XPF"
        },
        {
          "code": "PG",
          "phoneCode": "675",
          "name": "Papua New Guinea",
          "curSymbol": "K",
          "curCode": "PGK"
        },
        {
          "code": "PH",
          "phoneCode": "63",
          "name": "Philippines",
          "curSymbol": "₱",
          "curCode": "PHP"
        },
        {
          "code": "PK",
          "phoneCode": "92",
          "name": "Pakistan",
          "curSymbol": "Rs",
          "curCode": "PKR"
        },
        {
          "code": "PL",
          "phoneCode": "48",
          "name": "Poland",
          "curSymbol": "zł",
          "curCode": "PLN"
        },
        {
          "code": "PM",
          "phoneCode": "508",
          "name": "Saint Pierre and Miquelon",
          "curSymbol": "€",
          "curCode": "EUR"
        },
        {
          "code": "PN",
          "phoneCode": "64",
          "name": "Pitcairn",
          "curSymbol": "S",
          "curCode": "NZD"
        },
        {
          "code": "PR",
          "phoneCode": "1-787",
          "name": "Puerto Rico",
          "curSymbol": "S",
          "curCode": "USD"
        },
        {
          "code": "PS",
          "phoneCode": "970",
          "name": "Palestine",
          "curSymbol": "دينار",
          "curCode": "PSP"
        },
        {
          "code": "PT",
          "phoneCode": "351",
          "name": "Portugal",
          "curSymbol": "€",
          "curCode": "EUR"
        },
        {
          "code": "PW",
          "phoneCode": "680",
          "name": "Palau",
          "curSymbol": "S",
          "curCode": "USD"
        },
        {
          "code": "PY",
          "phoneCode": "595",
          "name": "Paraguay",
          "curSymbol": "₲",
          "curCode": "PYG"
        },
        {
          "code": "QA",
          "phoneCode": "974",
          "name": "Qatar",
          "curSymbol": "",
          "curCode": "QAR"
        },
        {
          "code": "RE",
          "phoneCode": "262",
          "name": "Réunion",
          "curSymbol": "€",
          "curCode": "EUR"
        },
        {
          "code": "RO",
          "phoneCode": "40",
          "name": "Romania",
          "curSymbol": "lei",
          "curCode": "RON"
        },
        {
          "code": "RS",
          "phoneCode": "381",
          "name": "Serbia",
          "curSymbol": "дин.",
          "curCode": "RSD"
        },
        {
          "code": "RU",
          "phoneCode": "7",
          "name": "Russian Federation",
          "curSymbol": "₽",
          "curCode": "RUB"
        },
        {
          "code": "RW",
          "phoneCode": "250",
          "name": "Rwanda",
          "curSymbol": "RWF",
          "curCode": "RWF"
        },
        {
          "code": "SA",
          "phoneCode": "966",
          "name": "Saudi Arabia",
          "curSymbol": "",
          "curCode": "SAR"
        },
        {
          "code": "SB",
          "phoneCode": "677",
          "name": "Solomon Islands",
          "curSymbol": "S",
          "curCode": "SBD"
        },
        {
          "code": "SC",
          "phoneCode": "248",
          "name": "Seychelles",
          "curSymbol": "SRe",
          "curCode": "SCR"
        },
        {
          "code": "SD",
          "phoneCode": "249",
          "name": "Sudan",
          "curSymbol": "S",
          "curCode": "SDG"
        },
        {
          "code": "SE",
          "phoneCode": "46",
          "name": "Sweden",
          "curSymbol": "kr",
          "curCode": "SEK"
        },
        {
          "code": "SG",
          "phoneCode": "65",
          "name": "Singapore",
          "curSymbol": "S",
          "curCode": "SGD"
        },
        {
          "code": "SH",
          "phoneCode": "290",
          "name": "Saint Helena, Ascension and Tristan da Cunha",
          "curSymbol": "£",
          "curCode": "SHP"
        },
        {
          "code": "SI",
          "phoneCode": "386",
          "name": "Slovenia",
          "curSymbol": "€",
          "curCode": "EUR"
        },
        {
          "code": "SJ",
          "phoneCode": "47",
          "name": "Svalbard and Jan Mayen Islands",
          "curSymbol": "kr",
          "curCode": "NOK"
        },
        {
          "code": "SK",
          "phoneCode": "421",
          "name": "Slovakia",
          "curSymbol": "EUR",
          "curCode": "EUR"
        },
        {
          "code": "SL",
          "phoneCode": "232",
          "name": "Sierra Leone",
          "curSymbol": "Le",
          "curCode": "SLL"
        },
        {
          "code": "SM",
          "phoneCode": "378",
          "name": "San Marino",
          "curSymbol": "€",
          "curCode": "EUR"
        },
        {
          "code": "SN",
          "phoneCode": "221",
          "name": "Senegal",
          "curSymbol": "CFA",
          "curCode": "XOF"
        },
        {
          "code": "SO",
          "phoneCode": "252",
          "name": "Somalia",
          "curSymbol": "S",
          "curCode": "SOS"
        },
        {
          "code": "SR",
          "phoneCode": "597",
          "name": "Suriname",
          "curSymbol": "S",
          "curCode": "SRD"
        },
        {
          "code": "SS",
          "phoneCode": "211",
          "name": "South Sudan",
          "curSymbol": "£",
          "curCode": "SSP"
        },
        {
          "code": "ST",
          "phoneCode": "239",
          "name": "Sao Tome and Principe",
          "curSymbol": "Db",
          "curCode": "STN"
        },
        {
          "code": "SV",
          "phoneCode": "503",
          "name": "El Salvador",
          "curSymbol": "S",
          "curCode": "USD"
        },
        {
          "code": "SX",
          "phoneCode": "1-721",
          "name": "Sint Maarten (Dutch part)",
          "curSymbol": "NAƒ",
          "curCode": "ANG"
        },
        {
          "code": "SY",
          "phoneCode": "963",
          "name": "Syrian Arab Republic",
          "curSymbol": "",
          "curCode": "SYP"
        },
        {
          "code": "SZ",
          "phoneCode": "268",
          "name": "Swaziland",
          "curSymbol": "E",
          "curCode": "SZL"
        },
        {
          "code": "TC",
          "phoneCode": "1-649",
          "name": "Turks and Caicos Islands",
          "curSymbol": "S",
          "curCode": "USD"
        },
        {
          "code": "TD",
          "phoneCode": "235",
          "name": "Chad",
          "curSymbol": "FCFA",
          "curCode": "XAF"
        },
        {
          "code": "TF",
          "phoneCode": "262",
          "name": "French Southern Territories",
          "curSymbol": "€",
          "curCode": "EUR"
        },
        {
          "code": "TG",
          "phoneCode": "228",
          "name": "Togo",
          "curSymbol": "CFA",
          "curCode": "XOF"
        },
        {
          "code": "TH",
          "phoneCode": "66",
          "name": "Thailand",
          "curSymbol": "฿",
          "curCode": "THB"
        },
        {
          "code": "TJ",
          "phoneCode": "992",
          "name": "Tajikistan",
          "curSymbol": "смн",
          "curCode": "TJS"
        },
        {
          "code": "TK",
          "phoneCode": "690",
          "name": "Tokelau",
          "curSymbol": "S",
          "curCode": "NZD"
        },
        {
          "code": "TL",
          "phoneCode": "670",
          "name": "Timor-Leste",
          "curSymbol": "S",
          "curCode": "USD"
        },
        {
          "code": "TM",
          "phoneCode": "993",
          "name": "Turkmenistan",
          "curSymbol": "m.",
          "curCode": "TMT"
        },
        {
          "code": "TN",
          "phoneCode": "216",
          "name": "Tunisia",
          "curSymbol": "",
          "curCode": "TND"
        },
        {
          "code": "TO",
          "phoneCode": "676",
          "name": "Tonga",
          "curSymbol": "T$",
          "curCode": "TOP"
        },
        {
          "code": "TR",
          "phoneCode": "90",
          "name": "Turkey",
          "curSymbol": "₺",
          "curCode": "TRY"
        },
        {
          "code": "TT",
          "phoneCode": "1-868",
          "name": "Trinidad and Tobago",
          "curSymbol": "TT$",
          "curCode": "TTD"
        },
        {
          "code": "TV",
          "phoneCode": "688",
          "name": "Tuvalu",
          "curSymbol": "S",
          "curCode": "AUD"
        },
        {
          "code": "TW",
          "phoneCode": "886",
          "name": "Taiwan",
          "curSymbol": "NT$",
          "curCode": "TWD"
        },
        {
          "code": "TZ",
          "phoneCode": "255",
          "name": "Tanzania, United Republic of",
          "curSymbol": "TSh",
          "curCode": "TZS"
        },
        {
          "code": "UA",
          "phoneCode": "380",
          "name": "Ukraine",
          "curSymbol": "₴",
          "curCode": "UAH"
        },
        {
          "code": "UG",
          "phoneCode": "256",
          "name": "Uganda",
          "curSymbol": "USh",
          "curCode": "UGX"
        },
        {
          "code": "UM",
          "phoneCode": "1",
          "name": "US Minor Outlying Islands",
          "curSymbol": "S",
          "curCode": "USD"
        },
        {
          "code": "US",
          "phoneCode": "1",
          "name": "United States",
          "curSymbol": "S",
          "curCode": "USD"
        },
        {
          "code": "UY",
          "phoneCode": "598",
          "name": "Uruguay",
          "curSymbol": "$U",
          "curCode": "UYU"
        },
        {
          "code": "UZ",
          "phoneCode": "998",
          "name": "Uzbekistan",
          "curSymbol": "сўм",
          "curCode": "UZS"
        },
        {
          "code": "VA",
          "phoneCode": "379",
          "name": "Holy See (Vatican City State)",
          "curSymbol": "€",
          "curCode": "EUR"
        },
        {
          "code": "VC",
          "phoneCode": "1-784",
          "name": "Saint Vincent and the Grenadines",
          "curSymbol": "S",
          "curCode": "XCD "
        },
        {
          "code": "VE",
          "phoneCode": "58",
          "name": "Venezuela, Bolivarian Republic of",
          "curSymbol": "Bs.S",
          "curCode": "VES"
        },
        {
          "code": "VG",
          "phoneCode": "1-284",
          "name": "Virgin Islands, British",
          "curSymbol": "S",
          "curCode": "USD"
        },
        {
          "code": "VI",
          "phoneCode": "1-340",
          "name": "Virgin Islands, U.S.",
          "curSymbol": "S",
          "curCode": "USD"
        },
        {
          "code": "VN",
          "phoneCode": "84",
          "name": "Vietnam",
          "curSymbol": "₫",
          "curCode": "VND"
        },
        {
          "code": "VU",
          "phoneCode": "678",
          "name": "Vanuatu",
          "curSymbol": "VT",
          "curCode": "VUV"
        },
        {
          "code": "WF",
          "phoneCode": "681",
          "name": "Wallis and Futuna Islands",
          "curSymbol": "₣",
          "curCode": "XPF"
        },
        {
          "code": "XK",
          "phoneCode": "383",
          "name": "Kosovo",
          "curSymbol": "€",
          "curCode": "EUR"
        },
        {
          "code": "WS",
          "phoneCode": "685",
          "name": "Samoa",
          "curSymbol": "ST",
          "curCode": "WST"
        },
        {
          "code": "YE",
          "phoneCode": "967",
          "name": "Yemen",
          "curSymbol": "",
          "curCode": "YER"
        },
        {
          "code": "YT",
          "phoneCode": "262",
          "name": "Mayotte",
          "curSymbol": "€",
          "curCode": "EUR"
        },
        {
          "code": "ZA",
          "phoneCode": "27",
          "name": "South Africa",
          "curSymbol": "R",
          "curCode": "ZAR"
        },
        {
          "code": "ZM",
          "phoneCode": "260",
          "name": "Zambia",
          "curSymbol": "K",
          "curCode": "ZMK"
        },
        {
          "code": "ZW",
          "phoneCode": "263",
          "name": "Zimbabwe",
          "curSymbol": "Z$",
          "curCode": "ZWD"
        }
      ];
    export default countries;